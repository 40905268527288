<template>
    <div class="upload-form">
      <input type="file" @change="handleFileUpload" accept=".heic" />
      <button @click="convertFile" :disabled="!file || loading">Convert to PDF</button>
      <div v-if="loading" class="loading">Converting...</div>
      <a v-if="downloadUrl && !loading" :href="downloadUrl" download="converted.pdf">Download PDF</a>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
  </template>
  
  <script>
  import heic2any from 'heic2any';
  import { jsPDF } from 'jspdf';
  
  export default {
    name: 'UploadForm',
    data() {
      return {
        file: null,
        downloadUrl: '',
        errorMessage: '',
        loading: false
      };
    },
    methods: {
      handleFileUpload(event) {
        this.file = event.target.files[0];
      },
      async convertFile() {
        if (!this.file) return;
  
        this.loading = true;
        this.errorMessage = '';
        this.downloadUrl = '';
  
        try {
          // Convert HEIC to JPG
          const blob = await heic2any({ blob: this.file, toType: 'image/jpeg' });
  
          // Create an image element
          const img = document.createElement('img');
          img.src = URL.createObjectURL(blob);
          img.onload = () => {
            // Create a PDF
            const pdf = new jsPDF();
            const width = pdf.internal.pageSize.getWidth();
            const height = (img.height * width) / img.width;
            pdf.addImage(img, 'JPEG', 0, 0, width, height);
            const pdfBlob = pdf.output('blob');
  
            // Create a download URL for the PDF
            this.downloadUrl = URL.createObjectURL(pdfBlob);
            this.loading = false;
          };
          img.onerror = () => {
            this.errorMessage = 'Image loading failed. Please try again.';
            this.loading = false;
          };
        } catch (error) {
          this.errorMessage = 'File conversion failed. Please try again.';
          this.loading = false;
        }
      }
    }
  }
  </script>
  
  <style>
  .upload-form {
    margin: 0 auto;
    max-width: 400px;
    text-align: left;
  }
  
  .upload-form input {
    display: block;
    margin-bottom: 1em;
  }
  
  .upload-form button {
    display: block;
    margin-bottom: 1em;
    padding: 0.5em 1em;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .upload-form .loading {
    margin-bottom: 1em;
    color: #4CAF50;
  }
  
  .upload-form a {
    display: block
  }
  </style>
  