<template>
  <div id="app">
    <header>
      <h1>HEIC to PDF Converter</h1>
    </header>
    <main>
      <UploadForm />
    </main>
    <footer>
      <p>Free to use. No login required.</p>
    </footer>
  </div>
</template>

<script>
import UploadForm from './components/UploadForm.vue'
import { inject } from "@vercel/analytics";

export default {
  name: 'App',
  components: {
    UploadForm
  },
  mounted() {
    inject();
  }
}
</script>

<style>
#app {
  text-align: center;
}

header {
  background-color: #4CAF50;
  color: white;
  padding: 1em 0;
}

main {
  padding: 2em;
}

footer {
  margin-top: 2em;
  padding: 1em 0;
  background-color: #f1f1f1;
}
</style>
